// src/screen/home/HomeScreen.js

import React from 'react';

function HomeScreen() {
    return (
        <div>
            <h1>스타팅블록</h1>
        </div>
    );
}

export default HomeScreen;
